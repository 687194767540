/*-- column visibility button --*/
.dt-button-collection .dt-button.buttons-columnVisibility {
   background: none;
   background-color: transparent;
   box-shadow: none;
   border: none;
   padding: 8px 15px;
   margin: 0;
   text-align: left;
   color: #000;
   font-weight: 600;
}
.dt-button-collection .buttons-columnVisibility:before, .dt-button-collection .buttons-columnVisibility.active span:before {
   display: block;
   position: absolute;
   top: 1.2em;
   left: 0;
   width: 12px;
   height: 12px;
   box-sizing: border-box;
}
.dt-button-collection .buttons-columnVisibility:before {
   content: " ";
   margin-top: -3px;
   margin-left: 15px;
   border: none;
   border-radius: 2px;
    /*background: #005CC8;
    */
    background: #0a3970;
}
.dt-button-collection .buttons-columnVisibility.active span:before {
   content: "\2714";
   margin-top: -2px;
   margin-left: 15px;
   text-align: center;
   color: #fff;
   font-size: 11px;
   font-weight: 400;
}
.dt-button-collection .buttons-columnVisibility span {
   margin-left: 18px;
}
.dt-button-collection .dt-button.buttons-columnVisibility span {
   display: flex;
}
.dt-buttons button.dt-button {
   color: #7e8299;
   border: none;
   box-shadow: none;
   border-radius: .475rem;
   padding: 7px 25px 7px 12px;
   background-color: #f1f1f1;
   position: relative;
}
.dt-buttons button.dt-button.buttons-collection.buttons-colvis:after{
   content: '';
   background-image: url(./img/dropdown-arrow.png);
   position: absolute;
   right: 8px;
   top: 50%;
   transform: translateY(-50%);
   height: 100%;
   width: 10px;
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
}
/*column visibility dropdown*/
div.dt-button-collection-title {
   text-align: center;
   padding: 0.3em 0 0.5em;
   font-size: 0.9em;
}
div.dt-button-collection-title:empty {
   display: none;
}
div.dt-button-collection {
   position: absolute;
   top: 0;
   left: 0;
   min-width: 250px;
   margin-top: 3px;
   padding: 0;
   border: none;
    /*background-color: #B2D4FF;
    */
    background-color: #e7f2f6;
    overflow: hidden;
    z-index: 1;
    border-radius: 0.475rem;
}
div.dt-button-collection div[role='menu']{
   max-height: 300px;
   overflow-y: auto;
   position: relative;
   padding: 8px 0;
}
div.dt-button-collection button.dt-button, div.dt-button-collection div.dt-button, div.dt-button-collection a.dt-button {
   position: relative;
   left: 0;
   right: 0;
   width: 100%;
   display: block;
   float: none;
   margin-bottom: 4px;
   margin-right: 0;
}
div.dt-button-collection button.dt-button.buttons-colvisRestore, div.dt-button-collection button.dt-button.buttons-colvisGroup {
   max-width: 95%;
   margin: 0 auto 3px;
   border-radius: .475rem;
    /*background: #fff;
    */
    background: #0a3970;
}
div.dt-button-collection button.dt-button.buttons-colvisRestore span, div.dt-button-collection button.dt-button.buttons-colvisGroup span{
   color: #fff;
}
div.dt-button-collection.fixed {
   position: fixed;
   top: 50%;
   left: 50%;
   margin-left: -75px;
   border-radius: 0;
}
/*datatables custom */
table.dataTable.nowrap th, table.dataTable.nowrap td {
   white-space: nowrap;
   cursor: default;
}
.dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate , .toolbar-datatable{
   position: absolute;
}
.dataTables_wrapper .dataTables_info {
   bottom: 15px;
   left: 30px;
}
.dataTables_wrapper .dataTables_paginate {
   right: 30px;
   bottom: 15px;
}
div.dataTables_wrapper div.dataTables_filter {
   position: absolute;
   right: 2.25rem;
   top: 0.75rem;
}
.dataTables_filter input:focus{
   outline: 0;
}
.dataTables_wrapper .dataTables_filter input {
   border: 1px solid #fff;
   border-radius: 0.475rem;
   padding: 7px 15px;
   background-color: #f1f1f1;
}
.card-body .dataTables_wrapper .dataTables_paginate .paginate_button.current, .card-body .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
   z-index: 3;
   color: #fff;
   background-color: #0a3971;
   border-color: transparent;
   background-image: none;
   border: 1px solid #0a3971;
   border-radius: 0.475rem;
   padding: 0.5em 1em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
   box-sizing: border-box;
   display: inline-block;
   min-width: 1.5em;
   padding: 0.5em 1em;
   margin-left: 2px;
   text-align: center;
   text-decoration: none;
   cursor: pointer;
   color: #333;
   border: 1px solid transparent;
   border-radius: 0.475rem;
}
.card-body .dataTables_wrapper{
   margin-bottom: 3.75rem;
}
table.dataTable {
   width: 100%;
    /* margin: 0 auto;
    */
    clear: both;
    border-collapse: separate;
    border-spacing: 0 
}
th, td {
   white-space: nowrap;
}
div.dataTables_wrapper {
   width: 100%;
   margin: 0 auto;
}
table.dataTable thead th, table.dataTable tfoot th {
   font-weight: 600;
}
table.dataTable thead th, table.dataTable thead td {
   padding: 1rem;
}
table.dataTable tbody tr {
   background-color: #fff 
}
table.dataTable tbody th, table.dataTable tbody td {
   padding: 8px 10px 
}
table.dataTable td.dataTables_empty {
   text-align: left;
   z-index: 1;
   position: relative;
}
.dataTable thead th:last-child{
   border-bottom-right-radius: .475rem;
   border-top-right-radius: .475rem;
}
.dataTables_wrapper .dataTables_filter {
   float: right;
   text-align: right 
}
.dataTables_wrapper .dataTables_info {
   clear: both;
   float: left;
   padding-top: .755em 
}
.dataTables_wrapper .dataTables_paginate {
   float: right;
   text-align: right;
   padding-top: .25em 
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
   cursor: default;
   color: #666 !important;
   border: 1px solid transparent;
   background: transparent;
   box-shadow: none 
}
.dataTables_wrapper .dataTables_paginate .ellipsis {
   padding: 0 1em 
}
.dataTables_wrapper .dataTables_processing {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   height: 40px;
   margin-left: -50%;
   margin-top: -25px;
   padding-top: 20px;
   text-align: center;
   font-size: 1.2em;
}
.dataTables_wrapper .dataTables_scroll {
   clear: both;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
   -webkit-overflow-scrolling: touch;
}
.dataTables_wrapper:after {
   visibility: hidden;
   display: block;
   content: "";
   clear: both;
   height: 0 
}
.dataTables_wrapper span.animate-on-change.animate-on-change-in{
   color:#000;
   font-weight:500;
}

div.dataTables_wrapper div.dataTables_length label select:focus{
    outline: 0;
}
div.dataTables_wrapper div.dataTables_length label select {
    padding: 3px 4px;
    border: 1px solid #303030;
    border-radius: .475rem;
    margin:  0 5px;
}

/*MEDIA QUERIES*/
@media(min-width: 1200px){
   div.dt-buttons {
       position: absolute;
       top: 1.25rem;
       left: calc(100% - 383px);
   }
   div.dataTables_wrapper div.dataTables_filter {
       width: 13rem;
   }
   div.dataTables_wrapper div.dataTables_filter label, div.dataTables_wrapper div.dataTables_filter label input{
       width: 100%;
   }
}
@media(max-width: 1199px){
   .toolbar-datatable{
       position:static;
   }
   div.dataTables_wrapper div.dataTables_filter{
       top:50px;
   }
   .datatable-outer{
       margin-top:40px;
   }
   .datatable-col{
       justify-content:space-between;
   }
   div.dataTables_wrapper div.dataTables_length{
       padding-top: 0;
       margin-right: 15px;
   }
}
@media screen and (max-width: 767px) {
   .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
       float: none;
       text-align: center 
   }
   .dataTables_wrapper .dataTables_paginate {
       margin-top: .5em 
   }
}
@media screen and (max-width: 640px) {
   .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
       float: none;
       text-align: center 
   }
   .dataTables_wrapper .dataTables_filter {
       margin-top: .5em 
   }
}
